import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  figure {
    width: 450px;
  }

  > div {
    display: flex;
  }

  @media only screen and (max-width: 768px) {
    div {
      flex-direction: column;
      align-items: center;
    }

    figure {
      width: 100%;
    }
  }
  @media only screen and (max-width: 425px) {
    figure {
      width: 60%;
    }
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    text-align: center;
    margin-top: 2rem;
  }
`
export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 10.71rem;
  font-weight: 900;
  line-height: 12.57rem;

  @media only screen and (max-width: 768px) {
    font-size: 5.71rem;
    line-height: 6.57rem;
  }

  @media only screen and (max-width: 425px) {
    font-size: 3.71rem;
    line-height: 4.57rem;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  line-height: 1.71rem;
  margin: 2rem 0;
  max-width: 300px;
  width: 100%;
`

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Logo from 'components/Logo'
import Button from 'components/Button'

import NotFoundImg from 'images/not-found-404.png'

const NotFoundPage = ({ location }) => {
  return (
    <Layout locale="hr">
      <SEO title="404 | Rentlio" lang={'hr'} location={location} />
      <Wrapper>
        <div>
          <figure>
            <img src={NotFoundImg} alt="404" />
          </figure>
          <Content>
            <Logo mode="dark" locale="hr" />
            <Title>404</Title>
            <Description>
              Ups, stranicu koju tražite ne možemo pronaći! Izgleda da ste se izgubili. Vratite se nazad!
            </Description>
            <Button type="large" href="/">
              Vrati se
            </Button>
          </Content>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage
